import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import Utils from '../../services/utils';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const CartSummary = observer(({
    setShow, 
    bonusUse, 
    deliveryStock, 
    promocodeInfo, 
    setDeliveryInZones,
    loading,
    deliveryPrice,
    setDeliveryPrice
}) => {
    const {app, user} = useContext(Context);

    const [total, setTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();
    const [subTotal, setSubTotal] = useState(0);

    const [maxDeliveryPrice, setMaxDeliveryPrice] = useState(0);

    const [promoDiscount, setPromoDiscount] = useState();
    const [promoDelivery, setPromoDelivery] = useState();
    const [deliveryZones, setDeliveryZones] = useState();

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            let temp2 = 0;
            let temp3 = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                temp2 += Number(user.cartProducts[i]?.old_price ?  user.cartProducts[i]?.old_price : user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                if(user.cartProducts[i].bonus?.type == 1){
                    temp3 += (Math.floor((Number(user.cartProducts[i].price) * Number(user.cartProducts[i].bonus.number))/100) * Number(user.cartProducts[i].count))
                }else if(user.cartProducts[i].bonus){
                    temp3 += (Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
                }
            }
            setTotal(temp);
            setSubTotal(temp2);
            if(temp3 && temp3 > 0){
                setBonuses(temp3);
            }
            if(temp !== temp2){
                setDiscount(Number(temp2)-Number(temp))
            }else{
                setDiscount(null)
            }
        }
    }, [user.cartProducts])

    const fetchDeliveryZones = async () => {
        let type = app.settings?.find(it => it.type === 'delivery_zone_enabled')?.value;
        if(type){
            let str = `delivery_id=${user.address?.delivery_id}`; 
            const res = await UserServices.cart.order.deliveryZones(str);
            if(res.statusCode === 200){
                setDeliveryZones(res.content.items);
            }else{
                toast.error(res.message)
            }
        }
    }

    useEffect(() => {
        fetchDeliveryZones();
    }, [user.address])

    useEffect(() => {
        if(promocodeInfo){
            console.log(promocodeInfo)
            if(promocodeInfo.type?.slug === "min_price_discount"){
                let temp = null;
                for(let i = promocodeInfo.value?.length - 1; i>=0; i--){
                    if(promocodeInfo.value[i].min_price < total){
                        temp = promocodeInfo.value[i];
                        break
                    }
                }
                if(temp){
                    if(temp.value_type === 1){
                        setPromoDiscount(Math.floor(Number(temp.value) * total/ 100))
                    }else if(temp.value_type === 2){
                        setPromoDiscount(Number(temp.value))
                    }
                }
            }else if(promocodeInfo.type.slug === "min_price_delivery"){
                setPromoDelivery(true)
            }
        }else{
            setPromoDiscount(null)
            setPromoDelivery(null)
        }
    }, [promocodeInfo])

    const userProfile = async () => {
        const res = await UserServices.profile.index();
        if(res.statusCode === 200){
            user.setBalance(res.content.balance);
        }
    }

    useEffect(() => {
        if(user.token){
            userProfile();
        }
    }, [])

    const getPrice = async () => {
        const newData = {
            branch_id: app.branch?.value,
            delivery_id:user?.address?.delivery_id,
            address_id_to: user?.address?.pickup_id,
            address: user.address,
            order_uid: user?.uid,
        }

        const res = await UserServices.cart.getPrice(newData);

        if(res.statusCode === 200){
            setDeliveryPrice(res.content)
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        if(user.address){
            getPrice();

            const intervalId = setInterval(() => {
                getPrice();
            }, 60000);

            return () => clearInterval(intervalId);
        }
    }, [user.address])

    const calculateTotalWithDeliveryAndBonuses = () => {
        let totalWithDelivery = total;

        if(promoDelivery){
            if (bonusUse) {
                if(user.balance > total){
                    totalWithDelivery -= Number(total);
                }else{
                    totalWithDelivery -= Number(user.balance);
                }
            }
        }
        else if (deliveryPrice && !deliveryStock) {
            totalWithDelivery += Number(deliveryPrice);
            if (bonusUse) {
                if(user.balance > total){
                    totalWithDelivery -= Number(total);
                }else{
                    totalWithDelivery -= Number(user.balance);
                }
            }
            if(promoDiscount){
                totalWithDelivery -= Number(promoDiscount);
            }
        }else if (bonusUse) {
            if(user.balance > total){
                totalWithDelivery -= Number(total);
            }else{
                totalWithDelivery -= Number(user.balance);
            }
            if(promoDiscount){
                totalWithDelivery -= Number(promoDiscount);
            }
        }else if (promoDiscount) {
            totalWithDelivery -= Number(promoDiscount);
        }

        return totalWithDelivery;
    };
  return (
    <div className='cart-summary'>
        <div className='flex'>
            <h5>{app.localizationsItems?.sub_total}</h5> <h5>{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</h5>
        </div>

        <div className='cart-summary-wrapper'>
            {promoDelivery ?
                <div className='list-item'>{app.localizationsItems?.promo_delivery}<span> {app.localizationsItems?.free}</span></div>
                :
                deliveryStock 
                ? 
                <div className='list-item'>{app.localizationsItems?.delivery}<span> {app.localizationsItems?.free}</span></div>
                :
                <div className='list-item'>{app.localizationsItems?.delivery}<span> 
                    {deliveryPrice > 0 ? 
                        <>{Utils.numberWithSpaces(deliveryPrice)} {app.localizationsItems?.currency}</> 
                        :
                        app.localizationsItems?.free
                    }
                    </span>
                </div>
            }
            {bonusUse && 
                <div className='list-item'>{app.localizationsItems?.used_bonuses}<span>- 
                    {user.balance > total ?
                        <>{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</>
                        :
                        <>{Utils.numberWithSpaces(user.balance)} {app.localizationsItems?.currency}</>
                    }
                </span>
            </div>}
            {discount && <div className='list-item'>{app.localizationsItems?.discount_price}<span className='discount'>- {Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</span></div>}
            {promoDiscount && <div className='list-item'>{app.localizationsItems?.promo_discount}<span className='discount'>- {Utils.numberWithSpaces(promoDiscount)} {app.localizationsItems?.currency}</span></div>}
        </div>
        
        <div className='item-bottom'>
            <div className='flex'>
                <h3>{app.localizationsItems?.total}</h3> 
                <h3>{calculateTotalWithDeliveryAndBonuses() > 0 ? Utils.numberWithSpaces(calculateTotalWithDeliveryAndBonuses()) : 0} {app.localizationsItems?.currency}</h3>
            </div>
            {(app.bonusEnabled && bonuses) ? 
                <div className='list-item'>{app.localizationsItems?.bonuses} <span className="product-bonus"> <i className='icon-plus'></i> {Utils.numberWithSpaces(bonuses)} Б</span> </div>
            : <></>}
            
            <button className={loading ? 'main-btn blink-button loading' : 'main-btn blink-button'} disabled={Number(app.minimumOrderAmount) > total}>
                {app.localizationsItems?.checkout_order} <span className='flare'></span>
            </button>
            {Number(app.minimumOrderAmount) > total ? 
                <div className='red-text mt-1'>{app.localizationsItems?.minimal_sum_text} {Utils.numberWithSpaces(app.minimumOrderAmount)} {app.localizationsItems?.currency}</div>
                : <></>
            }
        </div>
    </div>
  )
})

export default CartSummary